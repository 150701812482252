/**
 * Applies inline CSS styles from `<span>` elements to their parent `<li>` elements.
 *
 * This function searches for `<span>` elements inside `<li>` elements in a given HTML structure.
 * It extracts the inline `fontSize`, `fontFamily`, and `color` styles from the `<span>` elements
 * and applies them directly to the closest `<li>` element.
 * Additionally, it ensures that list marker styles (`::marker`) are aligned with the applied styles.
 *
 * @param {string} html - A string containing a valid HTML fragment.
 * @returns {string} - The modified HTML with styles applied to the `<li>` elements.
 *
 * @example
 * // Input HTML:
 * const inputHtml = `
 * <ul>
 *   <li><span style="font-size: 16px; color: red;">Item 1</span></li>
 *   <li>Item 2</li>
 * </ul>`;
 *
 * // Function call:
 * const result = applySpanStylesToParentLists(inputHtml);
 *
 * // Resulting HTML:
 * // <ul>
 * //   <li style="font-size: 16px; color: red;">
 * //     <span style="font-size: 16px; color: red;">Item 1</span>
 * //   </li>
 * //   <li>Item 2</li>
 * // </ul>
 */

export const applySpanStylesToParentLists = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  // Select all <li> elements that have a <span> child
  const liElements = doc.querySelectorAll('li span');

  liElements.forEach(span => {
    const li = span.closest('li');

    if (li instanceof HTMLElement) {
      // Extract styles from the span
      const computedStyle = (span as HTMLSpanElement).style; // Using inline styles
      const fontSize = computedStyle.fontSize;
      const fontFamily = computedStyle.fontFamily;
      const color = computedStyle.color;

      // Apply the extracted styles directly to the <li>
      if (fontSize) {
        li.style.fontSize = fontSize;
      }
      if (fontFamily) {
        li.style.fontFamily = fontFamily;
      }
      if (color) {
        li.style.color = color;
      }

      // Ensure the ::marker styles are aligned
      li.style.setProperty('--marker-font-size', fontSize || 'inherit');
      li.style.setProperty('--marker-font-family', fontFamily || 'inherit');
      li.style.setProperty('--marker-color', color || 'inherit');
    }
  });

  return doc.body.innerHTML;
};
