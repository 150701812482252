import React from 'react';
import { useUserQuery } from '@vizsla/graphql';
/**
 * Please, do not use this hook directly, use `useCurrentUser` from `@vizsla/hooks` instead.
 * This was written to be used in the InboxProvider, to avoid a dependency cycle.
 *
 * @see {@link https://github.com/8base-services/vizsla/blob/develop/packages/hooks/src/user/useCurrentUser.tsx}
 */
export function useCurrentUserQuery() {
    const { data: response, error, loading } = useUserQuery({ fetchPolicy: 'cache-and-network' });
    const user = React.useMemo(() => response === null || response === void 0 ? void 0 : response.user, [response]);
    const userId = React.useMemo(() => user === null || user === void 0 ? void 0 : user.id, [user]);
    return { user, userId, error, loading };
}
