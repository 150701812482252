/* eslint-disable react/react-in-jsx-scope */
import { get, groupBy, omit } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Checkbox, FormControlLabel, Skeleton, Typography } from '@mui/material';

import {
  ShoppingCartRegistrationData,
  useAttendeeQuestions,
  useExperienceId,
  useNotification,
  useShoppingCartState,
  useModal,
} from '@vizsla/hooks';
import {
  FormState,
  QUESTIONS_FORM_FIELDS,
  QuestionsForm,
  QuestionsFormValues,
} from '@vizsla/components';
import { QUESTION_CATEGORIES_TITLES, MODALS } from '@vizsla/constants';
import { CartItem } from '@vizsla/types';
import { generateMinorEmail } from '@vizsla/utils';

import { SelectMinor } from 'src/modals';

import { Container, Content, Fields } from './ExperienceAttendeeForm.style';

/** @deprecated Use `QuestionsFormValues` directly. */
export type FormValues = QuestionsFormValues;

const ATTENDEES_GROUP = 'attendees';
const CUSTOM_QUESTIONS_GROUP = 'questions';

type ShoppingCartParent = CartItem<ShoppingCartRegistrationData>;

interface Props {
  index: number;
  referenceId: string;
}

export function ExperienceAttendeeForm(props: Props) {
  const { items: cart, edit: editCart, set: setCart } = useShoppingCartState();
  const { subscribe: subscribeForm } = useForm<QuestionsFormValues>();
  const { error: showError } = useNotification();
  const { change: setField, resetFieldState: resetField } = useForm();
  const [checked, setChecked] = useState<boolean>(false);
  const { openModal } = useModal();
  const experienceID = useExperienceId();
  const [fieldEmail, setFieldEmail] = useState<string | null>(null);

  const { questions: responseQuestions, loading } = useAttendeeQuestions({
    skip: !experienceID,
    variables: {
      experience: { id: experienceID },
    },
  });

  const attendeeGroup = `${ATTENDEES_GROUP}[${props.index}]`;
  const customQuestionsGroup = `${CUSTOM_QUESTIONS_GROUP}[${props.index}]`;
  const canBeMinor = props.index > 0;

  const FIELD_EMAIL = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.EMAIL}`;
  const FIELD_PHONE = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.PHONE}`;
  const FIELD_FIRST_NAME = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.FIRST_NAME}`;
  const FIELD_LAST_NAME = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.LAST_NAME}`;
  const FIELD_BIRTHDAY = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.BIRTHDAY}`;
  const FIELD_GENDER = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.GENDER}`;
  const FIELD_ADDRESS = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.ADDRESS}`;
  const FIELD_CITY = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.CITY}`;
  const FIELD_STATE = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.STATE}`;
  const FIELD_ZIP = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.ZIP}`;
  const FIELD_WAIVER_SIGNATURE = `${attendeeGroup}${QUESTIONS_FORM_FIELDS.WAIVER_SIGNATURE}`;

  useEffect(() => {
    return subscribeForm(watchFields, { values: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendeeGroup, customQuestionsGroup, props.referenceId]);

  const watchFields = (form: FormState<QuestionsFormValues>) => {
    const attendee: QuestionsFormValues = get(form.values, attendeeGroup);

    editCart({
      id: props.referenceId,
      attendee: omit(attendee, 'questions'),
      questions: attendee.questions,
    });
  };

  /** Return the current state of the item on the shopping cart state. */
  const item = useMemo(() => {
    const item = cart.find(item => item.id === props.referenceId);

    return item as ShoppingCartParent | undefined;
  }, [props.referenceId, cart]);

  /**
   * Return the possible parent for a minor.
   *
   */
  const parent = useMemo(() => {
    const item = cart.find(item => item.type === 'registration');

    return item as ShoppingCartParent | undefined;
  }, [cart]);

  const isMinor = Boolean(item?.parent);
  const optionName = item?.option?.name;

  /**
   * Filter and disable the questions based on the `isMinor` check.
   * https://8base-dev.atlassian.net/browse/VSL-1727
   */
  const ResetMinorFields = () => {
    const fields = [
      FIELD_PHONE,
      FIELD_FIRST_NAME,
      FIELD_LAST_NAME,
      FIELD_BIRTHDAY,
      FIELD_GENDER,
      FIELD_ADDRESS,
      FIELD_CITY,
      FIELD_STATE,
      FIELD_ZIP,
      FIELD_WAIVER_SIGNATURE,
    ];
    if (!isMinor) {
      setField(FIELD_EMAIL, undefined);
    }
    for (const field of fields) {
      try {
        // Reset the value of the field.
        setField(field, undefined);
        // Reset the validations and metadata of the field.
        resetField(field);
      } catch (error) {
        // eslint-disable-next-line no-continue
        continue;
      }
    }
  };
  const cleanAsMinor = () => {
    if (!item || !item.parent) {
      setField(FIELD_EMAIL, undefined);
      return;
    }

    ResetMinorFields();
    setCart(props.referenceId, omit(item, 'parent'));
  };
  const questions = useMemo(() => {
    if (isMinor === false) {
      if (fieldEmail !== null) {
        setField(fieldEmail, undefined);
      }
      return responseQuestions;
    }

    return responseQuestions.map(question => {
      if (question.name === 'email') {
        // Make these fields read-only
        // https://8base-dev.atlassian.net/browse/VSL-2085

        return { ...question, isDisabled: true, isHidden: true };
      }
      if (question.name === 'phone') {
        // Make these fields read-only
        return { ...question, isDisabled: true };
      }

      return question;
    });
  }, [isMinor, responseQuestions]);

  /** Group the `questions` by `category` in order to break into sections. */
  const sections = useMemo(() => {
    const sections = groupBy(questions, question => question.category);
    return Object.values(sections);
  }, [questions]);

  /**
   * Mark the current attendee as minor and set the primary attendee as `parent`.
   * https://8base-dev.atlassian.net/browse/VSL-1727
   */
  const markAsMinor = () => {
    if (!parent) {
      showError('No parent found on the current flow');
      return;
    }

    const parentEmail = parent.attendee?.email;
    const parentPhone = parent.attendee?.phone;

    if (!parentEmail) {
      showError('Parent does not have an email yet, cannot be marked');
      return;
    }

    setupMinorFields(parentEmail, parentPhone);
    editCart({ id: props.referenceId, parent: { email: parentEmail } });
  };

  /**
   * Cleans the current attendee (if was marked) as minor.
   * https://8base-dev.atlassian.net/browse/VSL-1727
   */
  const setupMinorFields = (parentEmail: string, parentPhone: string | undefined) => {
    const email = generateMinorEmail(parentEmail, { enumerator: `minor${props.index}` });

    setField(FIELD_EMAIL, email);
    setField(FIELD_PHONE, parentPhone);
  };

  const onChangeMinorCheck = (_event: unknown, checked: boolean) => {
    if (isMinor) {
      setChecked(false);
      cleanAsMinor();
      return;
    }
    setChecked(true);
    setFieldEmail(FIELD_EMAIL);
    openModal(MODALS.SELECT_MINOR, {
      parent,
      referenceId: props.referenceId,
      markAsMinor,
      setField,
      cleanAsMinor,
      setChecked,
      editCart,
      fields: {
        FIELD_EMAIL,
        FIELD_PHONE,
        FIELD_FIRST_NAME,
        FIELD_LAST_NAME,
        FIELD_BIRTHDAY,
        FIELD_GENDER,
        FIELD_ADDRESS,
        FIELD_CITY,
        FIELD_STATE,
        FIELD_ZIP,
      },
    });
  };

  if (loading) {
    return (
      <Container>
        <Skeleton variant="rectangular" width="45%" height="2rem" />

        <Fields>
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
        </Fields>

        <Skeleton variant="rectangular" width="30%" height="2rem" />

        <Fields>
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
          <Skeleton variant="rectangular" width="100%" height="3rem" />
        </Fields>
      </Container>
    );
  }

  if (questions.length === 0) {
    return (
      <Container>
        <Typography color="primary" variant="h4">
          Questions
        </Typography>

        <Content>
          <Typography>
            There&apos;s no questions available for the selected experience, you&apos;re free to
            continue.
          </Typography>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      {optionName && <Typography variant="h4">{optionName}</Typography>}

      {canBeMinor && (
        // Show the minor's check when is possible.
        // https://8base-dev.atlassian.net/browse/VSL-2084
        <Content>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={onChangeMinorCheck} />}
            label="Mark attendee as minor"
          />

          <Typography color="GrayText">
            By checking the attendee as a minor, the email and phone will default to that of the
            primary attendee.
          </Typography>
        </Content>
      )}

      <Content>
        {sections.map(questions => {
          const [first] = questions;
          const title = QUESTION_CATEGORIES_TITLES[first.category];

          return (
            <Fragment key={first.category}>
              <Typography color="primary" variant="h5">
                {title}
              </Typography>

              <Fields>
                <QuestionsForm name={attendeeGroup} questions={questions} />
              </Fields>
            </Fragment>
          );
        })}
      </Content>
      <SelectMinor />
    </Container>
  );
}
