/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  buildUrl,
  calculateShoppingCartTotal,
  formatTaxesAvalara,
  getTaxesBillingData,
} from '@vizsla/utils';
import { Modal, ShoppingCart, StripeBillingAddress } from '@vizsla/components';
import { useGetTaxesMutation, useRegisterAttendeesWithOutPaymentMutation } from '@vizsla/graphql';
import { createCartStore, useModal, useNotification, useShoppingCartState } from '@vizsla/hooks';
import { LANDING_ROUTES, MODALS } from '@vizsla/constants';

import { SupportPageLayout } from 'src/layouts';
import { useSelectedExperience, useOpenExperiencePages, useSelectedWaiver } from 'src/hooks';

import { ExperienceFee } from './ExperienceFee';
import { Button, Buttons, Container, Header, HeaderTitle } from './CheckoutPage.styles';

const useStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  modalButton: {
    margin: theme.spacing(1, 4, 2),
  },
}));

const selectTaxes = state => ({
  addItem: state.add,
  items: state.items,
});

const selectAdress = state => ({
  items: state.items,
  addItem: state.add,
});

const selectTotalSectionState = state => {
  return calculateShoppingCartTotal(state.items);
};

interface BillingInfo {
  name?: string;
  address?: {
    city?: string;
    country?: string;
    line1?: string;
    line2?: string | null;
    postal_code: string;
    state?: string;
  };
}

export const useBillingData = createCartStore<any>();

export const useIntegrationData = createCartStore<any>();

export function CheckoutPage() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [validateAddress, setValidateAddress] = React.useState(false);
  const total = useShoppingCartState(selectTotalSectionState);
  const [checkoutNext, setCheckoutNext] = useState(total > 0);
  const waiverInfo = useSelectedWaiver(state => state.data);
  const { addItem: addBillingInfo } = useBillingData(selectAdress);
  const { closeModal } = useModal(MODALS.NO_REQUIRED_FEE);

  const [dataAddress, setDataAddress] = useState<BillingInfo>({});
  const toaster = useNotification();
  const { openRegistrationForm: openOptionsSelector, openPayment } = useOpenExperiencePages();
  const selectItemsState = state => ({ items: state.items });
  const noFeeRequired = total <= 0;

  const { items: shoppingItems } = useShoppingCartState(selectItemsState);

  const formattedItems = shoppingItems?.map(item => {
    if (item?.attendee?.zip && typeof item?.attendee?.zip !== 'string') {
      return {
        ...item,
        attendee: {
          ...item.attendee,
          zip: item.attendee.zip.toString(),
          waiverSignature: waiverInfo?.waiverSignature ? waiverInfo?.waiverSignature : null,
        },
      };
    }
    return item;
  });

  const [registerAttendeesWithOutPaymentMutation, { loading: loadingRegisterAttendees }] =
    useRegisterAttendeesWithOutPaymentMutation();

  const { data: experience } = useSelectedExperience();
  const { experienceId } = useParams();

  const handleNext = async () => {
    try {
      if (total <= 0) {
        const payment = await registerAttendeesWithOutPaymentMutation({
          variables: {
            data: {
              experience: { id: experienceId },
              shoppingCart: formattedItems,
              billingInfo: dataAddress,
            },
          },
        });

        if (payment.data?.registerAttendeesWithOutPayment?.success) {
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId: String(experienceId) }),
          );
        } else {
          toaster.error(
            payment.data?.registerAttendeesWithOutPayment?.message ?? 'Something went wrong',
          );
          navigate(
            buildUrl(LANDING_ROUTES.EXPERIENCE_REGISTRATION, {
              experienceId: String(experienceId),
            }),
          );
        }
      } else {
        openPayment(experienceId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const disabled = useMemo(() => loading || !experience, [loading, experience]);

  useEffect(() => {
    async function getTaxes() {
      if (!complete && dataAddress.address) {
        try {
          setLoading(true);
          addBillingInfo({
            type: 'data',
            address: dataAddress,
          });
          if (!experienceId) {
            toaster.error(`Experience ID must be defined. Got ${typeof experienceId}`);
            return;
          }
          setCheckoutNext(false);
          setLoading(false);
        } catch (error) {
          const errorDetail = { error };
          if (errorDetail.error?.message.includes('Address')) {
            toaster.error('Invalid Address, please verify and try again.');
          } else {
            toaster.error('Something went wrong generating the payment, please try again');
          }
          console.error(error);
          setLoading(false);
        }
      }
    }
    getTaxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content spacing={4}>
        <Container>
          <Header>
            <HeaderTitle>Billing Information</HeaderTitle>
          </Header>

          {/** @adwher https://8base-dev.atlassian.net/browse/VSL-270?focusedCommentId=29334 */}

          <StripeBillingAddress
            complete={setComplete}
            getData={setDataAddress}
            validateAddress={validateAddress}
            setValidateAddress={setValidateAddress}
          />
        </Container>
        <ExperienceFee experienceId={experience?.id as string} campaignId={experience?.campaign?.id || ''} recalculate={checkoutNext} />
        <Buttons>
          <Button
            loading={loading || loadingRegisterAttendees}
            disabled={disabled}
            variant="outlined"
            onClick={handleStartOver}
          >
            Start Over
          </Button>

          <Button
            loading={loading || loadingRegisterAttendees}
            disabled={checkoutNext}
            onClick={handleNext}
          >
            Check Out
          </Button>
        </Buttons>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart experience={experience} showDiscount />
      </SupportPageLayout.Sidebar>
      <Modal isOpen={noFeeRequired} onClose={closeModal}>
        <div className={classes.modalContent}>
          <Typography component="p" className={classes.modalButton}>
            No fee required, Check Out.
          </Typography>
          <Button
            onClick={handleNext}
            disabled={!noFeeRequired}
            loading={loading || loadingRegisterAttendees}
          >
            Check Out
          </Button>
        </div>
      </Modal>
    </SupportPageLayout>
  );
}
