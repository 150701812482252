import { useMemo } from 'react';
import { useUserQuery, } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';
import { PERMISSION_LEVELS, PERMISSIONS_BY_LEVEL, PERMISSIONS_BY_ROLE, PERMISSIONS_INDEX, CLIENT_CONSUMER_ROLES, } from './constants';
const getHighestPermission = (permissions) => {
    return (permissions || []).reduce((highestPermission, currentPermission) => {
        const highestValue = PERMISSION_LEVELS[highestPermission];
        const currentValue = PERMISSION_LEVELS[currentPermission];
        return currentValue > highestValue ? currentPermission : highestPermission;
    }, 'noneAccess');
};
export function useCurrentUser(currentSections) {
    var _a, _b;
    const { data: response, loading, error, refetch, } = useUserQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
    });
    const data = useMemo(() => {
        var _a, _b, _c;
        const user = (_a = response === null || response === void 0 ? void 0 : response.user) !== null && _a !== void 0 ? _a : null;
        return {
            data: user,
            user: user,
            firstName: (_b = user === null || user === void 0 ? void 0 : user.firstName) !== null && _b !== void 0 ? _b : null,
            lastName: (_c = user === null || user === void 0 ? void 0 : user.lastName) !== null && _c !== void 0 ? _c : null,
            fullName: getUserFullName(user),
        };
    }, [response]);
    const hasClientConsumerRoles = useMemo(() => {
        var _a, _b, _c;
        const roles = (_c = (_b = (_a = data.user) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : [];
        return roles.some(role => CLIENT_CONSUMER_ROLES.includes(role.name));
    }, [data]);
    if (currentSections && currentSections.length > 0) {
        const vizslaOrganizationStaff = (_b = (_a = data.user) === null || _a === void 0 ? void 0 : _a.vizslaOrganizationStaff) === null || _b === void 0 ? void 0 : _b.items[0];
        if (vizslaOrganizationStaff && vizslaOrganizationStaff.roles) {
            const sectionPermissions = currentSections.flatMap(currentSection => vizslaOrganizationStaff.roles
                .map(role => PERMISSIONS_BY_ROLE[PERMISSIONS_INDEX[role]][currentSection])
                .filter(permission => permission));
            const highestPermission = getHighestPermission(sectionPermissions);
            return Object.assign(Object.assign(Object.assign({}, data), { loading,
                error,
                hasClientConsumerRoles }), PERMISSIONS_BY_LEVEL[highestPermission]);
        }
    }
    return Object.assign(Object.assign(Object.assign({}, data), { loading,
        error,
        hasClientConsumerRoles,
        refetch }), PERMISSIONS_BY_LEVEL.noneAccess);
}
