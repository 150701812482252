import { gql, useQuery } from '@apollo/client';

import {
  CampaignDonationFundraising,
  CampaignFundraisingSettingListResponse,
  CampaignFundraisingSettingUpdateInput,
  CampaignGeneralFundraising,
  CampaignIndividualFundraising,
  CampaignTeamFundraising,
} from '@vizsla/graphql';

export enum CampaignFundraisingSettingsTypes {
  donationSettings = 'donationSettings',
  generalSettings = 'generalSettings',
  teamSettings = 'teamSettings',
  individualSettings = 'individualSettings',
}

interface SetSettingsInput {
  settingsType: CampaignFundraisingSettingsTypes;
  data: CampaignFundraisingSettingUpdateInput;
}

type CampaignFundraisingSettingsOutput = {
  donationSettings: CampaignDonationFundraising | null;
  generalSettings: CampaignGeneralFundraising | null;
  teamSettings: CampaignTeamFundraising | null;
  individualSettings: CampaignIndividualFundraising | null;
  loading: boolean;
};

const CAMPAIGN_FUNDRAISING_SETTINGS_QUERY = gql`
  query FundraisingSettingsByCampaignId($filter: CampaignFundraisingSettingFilter) {
    campaignFundraisingSettingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;

export const useCampaignFundraisingSettings = (
  campaignId: string,
): CampaignFundraisingSettingsOutput => {
  const { data, loading: settingsLoading } = useQuery<{
    campaignFundraisingSettingsList: CampaignFundraisingSettingListResponse;
  }>(CAMPAIGN_FUNDRAISING_SETTINGS_QUERY, {
    variables: {
      filter: {
        campaign: {
          id: {
            equals: campaignId,
          },
        },
      },
    },
    skip: !campaignId,
  });

  const fundraisingSettings = data?.campaignFundraisingSettingsList?.items[0] || {};

  const {
    donationSettings = null,
    generalSettings = null,
    teamSettings = null,
    individualSettings = null,
  } = fundraisingSettings;

  const loading = settingsLoading;

  return {
    donationSettings,
    generalSettings,
    teamSettings,
    individualSettings,
    loading,
  };
};
