import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import _ from 'lodash';

import { convertFromUTCGeo } from '@vizsla/utils';
import { useTimeZoneByAddress } from '@vizsla/hooks';

const EXPERIENCE_TYPE = 'Registration';
export const useVerifyAvailabilityOptions = experience => {
  const [isEarlyBirdCodeValidOptions, setIsEarlyBirdCodeValid] = useState(false);
  const { timeZone } = useTimeZoneByAddress({
    experience,
  });
  const getDatesOptions = useMemo(() => {
    return {
      startDate: _.get(experience, 'experience.registrationOptions.items', []).map(item =>
        convertFromUTCGeo(item.registrationStartDate, timeZone),
      ),
      endDate: _.get(experience, 'experience.registrationOptions.items', []).map(item =>
        convertFromUTCGeo(item.registrationEndDate, timeZone),
      ),
    };
  }, [experience, timeZone]);

  const optionsAvailable = useMemo(() => {
    const currentDate = DateTime.now();
    const limitDate = convertFromUTCGeo(
      experience?.experience?.endDate || experience?.experience?.startDate,
      timeZone,
    );
    const experienceLimitDate = DateTime.fromISO(limitDate);

    if (experience?.experience?.experienceType !== EXPERIENCE_TYPE) {
      return {
        available: false,
        date: {
          init: experienceLimitDate.toFormat('yyyy-MM-dd'),
          hour: experienceLimitDate.toFormat('HH:mm:ss'),
          hourFormat: experienceLimitDate.toFormat('hh:mm a'),
        },
      };
    }
    if (experienceLimitDate <= currentDate) {
      return {
        available: false,
        date: {
          init: experienceLimitDate.toFormat('yyyy-MM-dd'),
          hour: experienceLimitDate.toFormat('HH:mm:ss'),
          hourFormat: experienceLimitDate.toFormat('hh:mm a'),
        },
      };
    }
    const datesNull = getDatesOptions.startDate.filter(date => date === null);
    if (datesNull.length > 0 && experienceLimitDate <= currentDate) {
      setIsEarlyBirdCodeValid(true);
      return { available: true, date: null };
    }
    /* ****************************** */

    const available: any[] = [];
    const notAvailable: any[] = [];
    for (let index = 0; index < getDatesOptions.startDate.length; index += 1) {
      const startDate = DateTime.fromISO(getDatesOptions.startDate[index]);
      const endDate = DateTime.fromISO(getDatesOptions.endDate[index]);

      if (startDate.diff(currentDate, 'days').days >= 0) {
        notAvailable.push({ init: startDate, start: true });
        // eslint-disable-next-line no-continue
        continue;
      }
      if (endDate && endDate.diff(currentDate, 'days').days <= 0) {
        notAvailable.push({ init: endDate, start: false });
        // eslint-disable-next-line no-continue
        continue;
      }
      available.push({ init: startDate });
    }
    if (available.length === 0) {
      const byInit = notAvailable.filter(item => item.start === true);
      if (byInit.length > 0) {
        const byInitDates = byInit.map(item => item.init);
        const dateMin = DateTime.min(...byInitDates);
        return {
          available: false,
          date: {
            init: dateMin.toFormat('yyyy-MM-dd'),
            hour: dateMin.toFormat('HH:mm:ss'),
            hourFormat: dateMin.toFormat('hh:mm a'),
          },
        };
      }
      const closed = notAvailable.filter(item => item.start === false);
      if (closed.length > 0) {
        const closedDates = closed.map(item => item.init);
        const dateMax = DateTime.max(...closedDates);
        return {
          available: false,
          date: {
            init: dateMax.toFormat('yyyy-MM-dd'),
            hour: dateMax.toFormat('HH:mm:ss'),
            hourFormat: dateMax.toFormat('hh:mm a'),
          },
        };
      }
    }
    // setIsEarlyBirdCodeValid(true);
    return { available: true, date: null };
  }, [getDatesOptions, experience]);

  return { optionsAvailable, isEarlyBirdCodeValidOptions };
};
