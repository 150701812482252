import * as yup from 'yup';
const emptyErrorMessage = "Can't be empty";
export const AddressSchema = yup
    .object({
    country: yup.string().nullable().required('Country is required'),
    street1: yup.string().trim(emptyErrorMessage).nullable().required('Address is required'),
    zip: yup
        .string()
        .matches(/^\d{5}$/, 'Must be a valid zip code')
        .nullable()
        .required('Zip code is required'),
    city: yup.string().trim(emptyErrorMessage).nullable().required('City is required'),
    state: yup.string().nullable().required('State is required'),
})
    .nullable()
    .required();
export const PhoneSchema = yup
    .object({
    number: yup
        .string()
        .matches(/^[0-9]{10}$/, { message: 'Number is invalid' })
        .nullable(),
})
    .nullable();
export const PhoneSchemaRequired = yup
    .object({
    number: yup
        .string()
        .matches(/^[0-9]{10}$/, { message: 'Phone is invalid' })
        .nullable()
        .required('Phone is required'),
})
    .nullable()
    .required('Phone is required');
export const BillingAddressValidationSchema = yup.object({
    street1: yup.string().nullable().required('Street is required'),
    state: yup.string().nullable().required('State is required'),
    city: yup.string().nullable().required('City is required'),
    zip: yup.string().nullable().required('ZIP is required'),
});
export const UrlSchema = yup.string().url('Url is invalid').nullable();
