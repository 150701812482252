import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

import { HtmlRenderer } from '@vizsla/components';
import { BorderRadius, Shade } from '@vizsla/theme';

/// FormContainer

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

/// FormSection

export const FormSection = styled(Grid)``;

FormSection.defaultProps = {
  container: true,
  spacing: 2,
};

/// FormTitle

export const FormTitle = styled(Typography)`
  margin-top: 24px;
`;

FormTitle.defaultProps = {
  variant: 'h5',
};

/// FormControl

export const FormControl = styled(Grid)``;

FormControl.defaultProps = {
  item: true,
  xs: 12,
};

/// HTMLRender

const HTML_RENDER_HEIGHT_UNITS = 30;

export const HTMLRender = styled(HtmlRenderer)`
  min-height: ${p => p.theme.spacing(HTML_RENDER_HEIGHT_UNITS)};
  max-height: ${p => p.theme.spacing(HTML_RENDER_HEIGHT_UNITS)};
  padding: ${p => p.theme.spacing(0, 1)};
  border: solid 1px ${Shade.Gray[200]};
  border-radius: ${BorderRadius.S};
  overflow-y: auto;
  margin: 24px 0;

  ul,
  ol {
    padding: ${p => p.theme.spacing(0, 3)};
    margin: 0;
  }

  ul li,
  ol li {
    margin: 0;
    font-size: 'inherit' !important;
    line-height: 0;

    &::marker {
      font-size: inheritpx !important;
      font-family: inherit;
    }
  }

  ul li span,
  ol li span {
    font-size: inherit !important; /* Asegúrate de que el span coincida con el li */
  }

  ul li::marker,
  ol li::marker {
    all: unset;
    font-size: inherit;
    color: inherit;
  }

  blockquote {
    border-left: 5px solid #f1f1f1;
    margin: ${p => p.theme.spacing(1, 0)};
    padding-left: ${p => p.theme.spacing(1)};
  }

  pre {
    background-color: #f1f1f1;
    padding: ${p => p.theme.spacing(2)};
    overflow-x: auto;
  }
`;
