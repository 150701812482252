import * as Yup from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import { DateTime } from 'luxon';

import { Question, QuestionFormats, QuestionTypes } from '@vizsla/types';

const isMultipleChoice = (question: Question) => {
  if (question.type === QuestionTypes.Checkbox) return true;
  if (question.type === QuestionTypes.Select)
    return question.format === QuestionFormats.multipleChoice;
  return false;
};
const isNumericField = (question: Question) => {
  if (question.type !== QuestionTypes.Text) return false;
  const numberFormats = [QuestionFormats.number, QuestionFormats.money];
  return numberFormats.includes(question.format);
};

const getFieldSchema = (question: Question): Yup.AnySchema => {
  // handle multiple choice (Select and Checkbox)
  if (isMultipleChoice(question)) return Yup.array().of(Yup.string().oneOf(question.options));
  // handle type text with number format
  if (isNumericField(question)) return Yup.number().typeError('Enter a valid number');
  // handle type text with email format
  if (question.format === QuestionFormats.email) return Yup.string().email('Enter a valid email');
  // handle type Date
  if (question.type === QuestionTypes.Date) return Yup.date().typeError('Enter a valid date');
  // handle phone number
  return Yup.string();
};

const addRequiredValidation = (field: Yup.AnySchema, question: Question) => {
  const allowMultipleChoice = isMultipleChoice(question);
  if (!question.isMandatory) return field.nullable();

  if (!allowMultipleChoice) return field.required('Required');
  return (field as Yup.ArraySchema<Yup.AnySchema>).min(1, 'Minimum 1 option is required');
};

const buildCustomQuestionsSchema = (questions: Question[]) => {
  return questions.reduce((acc, question) => {
    let field = getFieldSchema(question);
    // add required validation
    field = addRequiredValidation(field, question);
    // add to object
    acc[question.name] = field;
    return acc;
  }, {} as ObjectShape);
};

const buildDefaultQuestionsSchema = (
  questions: Question[],
  ageRequirement: number | null,
  validAgeFrom: string | null,
) => {
  const commonSchema = buildCustomQuestionsSchema(questions);

  if (ageRequirement && validAgeFrom) {
    const currentDate = DateTime.fromISO(validAgeFrom).toJSDate() || new Date();

    const minAgeRequirementDate = DateTime.fromJSDate(currentDate)
      .minus({ years: ageRequirement })
      .toJSDate();

    commonSchema.birthday = Yup.date()
      .required('Date of birth is required')
      .max(minAgeRequirementDate, `You must be over ${ageRequirement} years old to register`);
  }

  if (Object.hasOwn(commonSchema, 'zip')) {
    commonSchema.zip = (commonSchema.zip as Yup.StringSchema).test(
      'is-five-digits',
      'Invalid Format',
      value => {
        if (value === undefined || value === null || typeof value !== 'string') {
          return true;
        }
        return Number(value) >= 500 && Number(value) <= 99999;
      },
    );
  }

  if (Object.hasOwn(commonSchema, 'phone')) {
    commonSchema.phone = (commonSchema.phone as Yup.NumberSchema).test(
      'is-five-digits',
      'Invalid Format',
      value => {
        if (value === undefined || value === null) {
          return true;
        }
        return value >= 1000000000 && value <= 9999999999;
      },
    );
  }
  return commonSchema;
};
interface ExperienceRegistrationSchemaInput {
  waiverEnabled: boolean;
  countAttendees: number;
  defaultQuestions: Question[];
  customQuestions: Question[];
  ageRequirement: number | null;
  validAgeFrom: string;
}
export const buildExperienceRegistrationSchema = (input: ExperienceRegistrationSchemaInput) => {
  const { waiverEnabled, defaultQuestions, customQuestions, ageRequirement, validAgeFrom } = input;
  // define base schema
  const baseSchema: ObjectShape = {};
  // when waiver is enabled, we need to add the waiver fields to the schema
  if (waiverEnabled) {
    baseSchema.waiverAcceptance = Yup.boolean().oneOf(
      [true],
      'You must accept the waiver to continue',
    );
    baseSchema.waiverSignature = Yup.string().required('Please sign the waiver to continue');
  }
  // add default questions to schema
  const defaultQuestionsSchema = buildDefaultQuestionsSchema(
    defaultQuestions,
    ageRequirement,
    validAgeFrom,
  );
  baseSchema.attendees = Yup.array(Yup.object(defaultQuestionsSchema));
  // add custom questions to schema
  const customQuestionsSchema = buildCustomQuestionsSchema(customQuestions);
  baseSchema.questions = Yup.array().of(Yup.object().shape(customQuestionsSchema));
  return Yup.object().shape(baseSchema);
};
